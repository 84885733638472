import React, { Component } from "react";

export class BestSpreadContract extends Component {
  render() {
    return (
      <div className="spread_contract-container">
        <a
          className="market"
          href={`https://www.predictit.org/markets/detail/${this.props.marketId}`}
        >
          <div className="market-image">
            <img src={this.props.contractImage} alt={this.props.marketName} />
          </div>
          <div className="market-data">
            <h3>{this.props.contractName}</h3>
            <p>
              <span className="market-data-key">Market Name: </span>{" "}
              {`${this.props.marketName}`}
            </p>
            <p>
              <span className="market-data-key">Market ID:</span>{" "}
              {this.props.marketId}
            </p>
            <p>
              <span className="market-data-key">Potential Loss: </span>{" "}
              {`${this.props.loss}%`}
            </p>
            <p>
              <span className="market-data-key">Loser Buy: </span>{" "}
              {`${this.props.side.toUpperCase()}`}
            </p>
          </div>
        </a>
      </div>
    );
  }
}

export default BestSpreadContract;
