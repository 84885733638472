import React from "react";
import { Link } from "@reach/router";

const Markets = props => {
  return (
    <Link className="market" to={`/market/${props.market.id}`}>
      <div className="market-image">
        <img src={props.market.image} alt={props.market.name} />
      </div>
      <div className="market-data">
        <h3>
          {/* <a href={props.market.url}>{props.market.name}</a> */}
          {props.market.name}
        </h3>

        <p>
          <span className="market-data-key">Market ID:</span> {props.market.id}
        </p>
        <p>
          <span className="market-data-key">Potential negative risk: </span>{" "}
          {`$${props.market.negRisk.minWin.toFixed(2)}`}
        </p>
        <p>
          <span className="market-data-key">Sum of nos: </span>{" "}
          {`$${props.market.negRisk.sumNos.toFixed(2)}`}
        </p>
      </div>
    </Link>
  );
};

export default Markets;
