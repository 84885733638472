import React, { useState } from "react";

const useDropdown = (defaultState, dropdownOptions) => {
  const [state, setState] = useState(defaultState);
  const id = `use-dropdown-${name.replace(" ", "").toLowerCase()}`;

  const Dropdown = () => (
    <select
      id={id}
      className="select_main"
      value={state}
      onChange={e => setState(e.target.value)}
      onBlur={e => setState(e.target.value)}
      disabled={!dropdownOptions || dropdownOptions.length === 0}
    >
      {dropdownOptions
        ? dropdownOptions.map(item => (
            <option key={item.replace(" ", "")} value={item}>
              {item}
            </option>
          ))
        : null}
    </select>
  );

  return [Dropdown, state, setState];
};

export default useDropdown;
