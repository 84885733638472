/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";

export class ContractRow extends Component {
  state = { pricePerShare: Number };
  componentDidMount() {
    let pricePerShare = parseInt(
      this.props.contract.orders.noOrders[0].pricePerShare * 100
    );
    this.setState({ pricePerShare });
  }
  render() {
    return (
      <div className="contract_row">
        <img
          className="contract_image"
          src={this.props.contract.contractImageSmallUrl}
          alt={this.props.contract.contractName}
        />
        <div className="contract_data">
          <div className="contract_name col_6">
            {this.props.contract.contractName}
          </div>
          <div className="contract_bestNoQuantity col_2">
            {this.props.contract.orders.noOrders[0].quantity}
          </div>
          <div className="contract_bestNoPrice col_2">
            {`${Math.round(this.state.pricePerShare)}¢`}
          </div>
          <div
            className="contract_bestNoSpread col_2"
            onClick={() => this.props.makeEditable(this.props.contract)}
          >
            {this.props.editContract == this.props.contract.contractId ? (
              <input
                className="contract_quantity"
                type="number"
                max="9999"
                min="0"
                value={this.props.contractQuantity}
                onChange={e =>
                  this.props.handleQuantityChange(
                    e.target.value,
                    this.props.contract
                  )
                }
              ></input>
            ) : (
              <span className="contract_quantity">
                {this.props.contract.optSpread}
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ContractRow;
