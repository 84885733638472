import React, { Component } from "react";
import axios from "axios";
import Markets from "../components/Markets";

class Home extends Component {
  state = {
    markets: []
  };
  getNegRisk = () => {
    axios
      .get("/api/all")
      .then(res => {
        if (res.data) {
          console.log(res.data);
          this.setState({ markets: res.data });
        }
      })
      .catch(err => {
        if (err) {
          console.error(err);
        }
      });
  };

  componentDidMount() {
    this.getNegRisk();
  }
  render() {
    return (
      <div className="markets-container">
        {this.state.markets.map((market, i) => (
          <Markets market={market} key={i} />
        ))}
      </div>
    );
  }
}

export default Home;
