import React, { useEffect, useState } from "react";
import axios from "axios";
import SpreadNotifications from "../components/SpreadNotifications";
import BestSpreadContract from "../components/BestSpreadContract";

const BestSpread = () => {
  // state = {
  //   value: 0,
  //   marketId: "",
  //   contractName: "",
  //   contractImage: "",
  //   marketName: "",
  //   notificationValue: 0,
  //   notificationFrequency: 30,
  //   notificationFrequencyDenomination: "minutes"
  // };

  const [markets, setMarkets] = useState([]);

  const getBestSpread = () => {
    console.log("here");
    axios
      .get("/api/spread/profitable")
      .then(res => {
        if (res.data) {
          setMarkets(res.data);
          console.log(res.data);
        }
      })
      .catch(err => {
        if (err) {
          console.error(err);
        }
      });
  };

  useEffect(() => getBestSpread(), []);

  return (
    <div id="spread_container">
      <SpreadNotifications />
      <div className="spread_markets-container">
        {markets.map((market, i) => (
          <BestSpreadContract
            key={i}
            contractImage={market.contractImage}
            marketName={market.marketName}
            marketId={market.marketId}
            loss={market.loss}
            side={market.loserBuy}
            contractName={market.contractName}
            // notificationValue={market.notificationValue}
          />
        ))}
      </div>
    </div>
  );
};

export default BestSpread;
