import React, { Component } from "react";

export class SpreadNotifications extends Component {
  render() {
    return (
      <div className="spread_notifications">
        <div className="spread_header">
          <div className="col_3 center">Toggle Notifications</div>

          <div className="col_3 center">Min Value to Trigger (¢)</div>

          <div className="col_3 center">Maximum Frequency</div>
        </div>
        <div className="contract_row">
          <div className="col_3 center">
            <label className="switch">
              <input type="checkbox" />
              <span className="slider round"></span>
            </label>
          </div>
          <div className="col_3 center">
            <input
              className="contract_quantity"
              type="number"
              max="99"
              min="0"
              value={this.props.notificationValue}
              onChange={e => this.props.handleValueChange(e.target.value)}
            ></input>
          </div>
          <div className="col_3 center"></div>
          <div className="col_3 center"></div>
        </div>
      </div>
    );
  }
}

export default SpreadNotifications;
