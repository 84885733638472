/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { Component } from "react";
import axios from "axios";
import ContractRow from "../components/ContractRow";
import MarketDetailsInfo from "../components/MarketDetailsInfo";

export class MarketDetails extends Component {
  state = {
    marketName: "",
    contracts: [],
    editContract: null,
    contractQuantity: 0,
    loading: false,
    risk: 0,
    sumNos: 0
  };

  calcNegRisk = contracts => {
    let sumNos = 0;
    let ifNoSum = 0;
    let order = [];
    contracts.forEach(contract => {
      let noPrice = contract.bestNoPrice
        ? contract.bestNoPrice
        : contract.bestBuyNoCost
        ? contract.bestBuyNoCost
        : null;
      if (noPrice) {
        sumNos += 1 - noPrice;
      }

      if (noPrice) {
        let shares = parseInt(contract.optSpread);
        let ifYes = shares * -noPrice;
        let ifNo = Math.round((shares + ifYes) * 90) / 100;
        ifNoSum += ifNo;
        order.push({
          shares,
          ifYes,
          ifNo,
          noPrice
        });
      }
    });
    sumNos = Math.round(sumNos * 100) / 100;
    let minWin = null;
    order.forEach(item => {
      item.ifYesPayout =
        Math.round((item.ifYes + ifNoSum - item.ifNo) * 100) / 100;
      if (!minWin || item.ifYesPayout < minWin) {
        minWin = item.ifYesPayout.toFixed(2);
      }
    });
    this.setState({ risk: minWin, sumNos });
  };

  getContracts = id => {
    let startTime = Date.now();
    axios
      .get(`/api/market/${id}`)
      .then(res => {
        try {
          if (res.data) {
            this.calcNegRisk(res.data.contracts);
            this.setState({
              contracts: res.data.contracts,
              marketName: res.data.contracts[0].marketName
            });
            if (this.state.loading == true) {
              this.setState({ loading: false });
            }
            let loadTime = Date.now() - startTime;
            loadTime = (loadTime / 1000).toFixed(2);
            console.log(`load time: ${loadTime}s`);
          }
        } catch (err) {
          console.error(err);
        }
      })
      .catch(err => {
        if (err) {
          console.error(err);
        }
      });
  };

  handleRefresh = () => {
    this.setState({ loading: true });
    this.getContracts(this.props.marketID);
  };

  makeEditable = contract => {
    this.setState({
      editContract: contract.contractId,
      contractQuantity: contract.optSpread
    });
  };

  handleQuantityChange = (quantity, editedContract) => {
    this.setState({ contractQuantity: quantity });
    let quotient = quantity * (1 / editedContract.opt);
    let contracts = this.state.contracts;
    contracts.forEach(contract => {
      contract.optSpread =
        contract.contractId == editedContract.contractId
          ? quantity
          : Math.round(quotient * contract.opt);
    });
    this.calcNegRisk(contracts);
    this.setState({
      contracts
    });
  };

  handleWindowClick = e => {
    if (e.target.className != "contract_quantity") {
      this.setState({ editContract: null, contractQuantity: 0 });
    }
  };

  componentDidMount() {
    this.getContracts(this.props.marketID);
  }
  render() {
    return (
      <div className="marketDetails" onClick={e => this.handleWindowClick(e)}>
        <a
          href={`https://www.predictit.org/markets/detail/${this.props.marketID}`}
        >
          <h1 className="marketDetails_title">{this.state.marketName}</h1>
        </a>
        <MarketDetailsInfo
          handleRefresh={this.handleRefresh}
          loading={this.state.loading}
          risk={this.state.risk}
          sumNos={this.state.sumNos}
        />
        <div className="contracts_header">
          <div className="col_6">Contract</div>
          <div className="col_2">Max Available</div>
          <div className="col_2">Best No Price</div>
          <div className="col_2">Ideal Spread</div>
        </div>
        {this.state.contracts.map(contract =>
          contract.orders.noOrders ? (
            <ContractRow
              key={contract.contractId}
              contract={contract}
              editContract={this.state.editContract}
              makeEditable={this.makeEditable}
              contractQuantity={this.state.contractQuantity}
              handleQuantityChange={this.handleQuantityChange}
            />
          ) : null
        )}
      </div>
    );
  }
}

export default MarketDetails;
