import React, { Component } from "react";
import { Link } from "@reach/router";

export class Header extends Component {
  render() {
    return (
      <header>
        <div className="main-header">
          <nav>
            <Link className="logo" to="/">
              <img src={require("../assets/predictit.svg")} alt="PredictIt" />
              <span>Insights</span>
            </Link>
          </nav>
        </div>
        <div className="sub-header">
          <nav>
            <Link to="/bestspread">Best Spread</Link>
            <Link to="/">Arb</Link>
          </nav>
        </div>
      </header>
    );
  }
}

export default Header;
