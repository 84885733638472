/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from "react";
import useDropdown from "./useDropdown";

const MarketDetailsInfo = props => {
  const [alerts, setAlerts] = useState(false);
  const [AlertTypeDropdown] = useDropdown("Negative Risk", [
    "Negative Risk",
    "NO Sum"
  ]);
  const [alertValue, setAlertValue] = useState(0);

  return (
    <div className="market-details_info">
      <div className="contracts_header">
        <div className="col_2">Risk</div>
        <div className="col_2">Sum of Nos</div>
        <div className="col_2">Slack Alerts</div>
        <div className="col_2">Alert Type</div>
        <div className="col_2">Alert Value</div>
        <div className="col_2">Refresh</div>
      </div>
      <div className="contract_row">
        <div className="col_2">
          {props.risk
            ? props.risk > 0
              ? `($${props.risk})`
              : `$${-props.risk}`
            : null}
        </div>
        <div className="col_2">{`$${props.sumNos}`}</div>
        <div className="col_2">
          <label className="switch">
            <input
              type="checkbox"
              checked={alerts || false}
              onChange={() => setAlerts(!alerts)}
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="col_2">
          <AlertTypeDropdown />
        </div>
        <div className="col_2 center">
          <span>$</span>
          <input
            className="alert_value"
            type="number"
            onChange={() => setAlertValue()}
            value={alertValue}
          />
        </div>
        <div className="col_2">
          <img
            src={require("../assets/refresh.svg")}
            alt="refresh"
            className={props.loading ? "refresh loading" : "refresh"}
            onClick={props.handleRefresh}
          />
        </div>
      </div>
    </div>
  );
};

export default MarketDetailsInfo;
